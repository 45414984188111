<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl">
        <div v-if="mode === null" class="text-h4 col-md-9">Users</div>
        <div v-else class="text-h4 col-md-9">{{ mode }} User</div>
      </div>

      <div v-if="mode === null" class="row q-gutter-xl">
        <q-btn @click="setAction('Create')" color="primary">Add New User</q-btn>
        <q-btn @click="searchAllUsers = true" color="primary">Edit Existing User</q-btn>
        <q-btn @click="$router.push({ name: 'importUsers' })" color="primary">Import Users</q-btn>
      </div>

      <q-dialog v-model="searchAllUsers">
        <UserSearch scoped allowSelfSelect @userSelected="getUserData" @closeForm="searchAllUsers = false" />
      </q-dialog>

      <div v-if="mode !== null">
        <q-form @submit="mode === 'Edit' ? updateUser() : addUser()" @reset="setAction(null)" autocomplete="no">
          <div class="row q-mt-xl">
            <div class="col-4">
              <div class="text-h6 q-mt-sm text-right q-mr-lg">Basic Information</div>
            </div>
            <q-card flat class="col-8 bg-lmsBackground">
              <q-card-section class="row">
                <q-input class="col-5" filled v-model="user.first_name" label="First Name" :rules="formRules.firstName" autocomplete="no" />
                <div class="col-1"></div>
                <q-input class="col-5" filled v-model="user.last_name" label="Last Name" :rules="formRules.lastName" autocomplete="no" />
              </q-card-section>
              <q-card-section class="row">
                <q-input class="col-11" filled v-model="user.email" type="email" label="Email" :rules="formRules.email" autocomplete="no" />
              </q-card-section>
              <q-card-section class="row">
                <q-input class="col-11" @click="() => $refs.rBirthDate.show()" mask="##/##/####" fill-mask filled label="Birthday (Optional)" v-model="user.birthday" :disable="disableForm">
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer primaryText-text">
                      <q-popup-proxy ref="rBirthDate" transition-show="scale" transition-hide="scale">
                        <q-date today-btn v-model="user.birthday" @input="formatDate('birthday')" class="bg-lmsBackground primaryText-text" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </q-card-section>
            </q-card>
          </div>
          <div class="row q-mt-xl">
            <div class="col-4">
              <div class="text-h6 q-mt-sm text-right q-mr-lg">Employment Information</div>
            </div>
            <q-card flat class="col-8 bg-lmsBackground">
              <q-card-section class="row items-start">
                <q-select :disable="!$isAllowed(['||', 'site-admin', 'master-admin'], true)" class="col-5" filled emit-value map-options v-model="user.customer_uid" label="Location" :value="currentUserLocation" :options="$store.getters['locations/list']" popup-content-class="bg-lmsBackground primaryText-text" :rules="formRules.location" autocomplete="no"/>
                <div class="col-1"></div>
                <q-input class="col-5" filled v-model="user.job_title" label="Job Title (Optional)" />
              </q-card-section>
              <q-card-section class="row">
                <q-select :disable="$store.state.auth.user.uid === user.uid && !$isAllowed(['||', 'site-admin', 'master-admin'], true)" class="col-11" filled emit-value map-options multiple v-model="user.roles" label="Roles" :options="roles" popup-content-class="bg-lmsBackground primaryText-text" :rules="formRules.roles" autocomplete="no"/>
              </q-card-section>
              <q-card-section class="row">
                <q-select class="col-11" filled emit-value map-options multiple :options="$store.getters['departments/list']" v-model="user.departments" label="User Groups (Optional)" popup-content-class="bg-lmsBackground primaryText-text" autocomplete="no"/>
              </q-card-section>
              <q-card-section class="row">
                <q-input class="col-11" @click="() => $refs.rStartDate.show()" mask="##/##/####" fill-mask filled label="Start Date (Optional)" v-model="user.started_at" :disable="disableForm">
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer primaryText-text">
                      <q-popup-proxy ref="rStartDate" transition-show="scale" transition-hide="scale">
                        <q-date today-btn v-model="user.started_at" @input="formatDate('started_at')" class="bg-lmsBackground primaryText-text" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </q-card-section>
            </q-card>
          </div>
          <div class="row q-mt-xl">
            <div class="col-4">
              <div class="text-h6 q-mt-sm text-right q-mr-lg">Application Information</div>
            </div>
            <q-card flat class="col-8 bg-lmsBackground">
              <q-card-section class="row">
                <q-checkbox class="col-5" v-model="user.is_advocate" label="Advocate" :disable="disableForm" />
                <div class="col-1"></div>
                <q-checkbox class="col-5" v-model="user.is_exempt" label="Unrestricted Access" :disable="disableForm" />
              </q-card-section>
              <q-card-section v-if="mode === 'Edit'">
                <q-checkbox v-model="editPasswords" label="Edit User Password" />
              </q-card-section>
              <q-card-section v-if="mode === 'Create' || editPasswords" class="row">
                <q-input class="col-5" filled v-model="user.password" label="Password" type="password" :hint="user.password" :rules="formRules.password" />
                <div class="col-1"></div>
                <q-input class="col-5" filled v-model="user.password_confirmation" label="Confirm Password" type="password" :hint="user.password_confirmation" :rules="formRules.passwordConfirmation" lazy-rules />
              </q-card-section>
              <q-card-section class="row q-my-lg q-gutter-md">
                <q-btn class="col-3" type="submit" color="primary">Submit</q-btn>
                <q-btn flat type="reset">Cancel</q-btn>
              </q-card-section>
              <q-card-section v-if="mode === 'Edit' && $store.state.auth.user.uid !== user.uid" class="row justify-center">
                <q-btn flat @click="deleteUser">Delete User</q-btn>
              </q-card-section>
            </q-card>
          </div>
        </q-form>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import UserSearch from 'components/UserSearch'
import { date } from 'quasar'

export default {
  components: { UserSearch },
  data () {
    return {
      user: {
        first_name: null,
        last_name: null,
        email: null,
        job_title: null,
        customer_uid: null,
        departments: [],
        roles: null,
        is_advocate: false,
        is_exempt: false,
        is_activated: true,
        is_fulltime: false,
        birthday: null,
        started_at: null,
        password: null,
        password_confirmation: null
      },
      disableForm: false,
      searchAllUsers: false,
      mode: null,
      editPasswords: false
    }
  },
  computed: {
    formRules () {
      return {
        firstName: [ this.$formRules.required('First Name') ],
        lastName: [ this.$formRules.required('Last Name') ],
        email: [ this.$formRules.required('Email') ],
        location: [ this.$formRules.required('Location'), this.$formRules.minimum(1) ],
        roles: [ this.$formRules.required('Role'), this.$formRules.minimum(1) ],
        password: [ this.$formRules.required('Passowrd'), this.$formRules.minimum(5) ],
        passwordConfirmation: [ this.$formRules.confirmPassword(this.user.password) ]
      }
    },
    roles () {
      return this.$store.getters['auth/rolesList'].filter(role => {
        return (role.slug !== 'master-admin' && role.slug !== 'site-admin' && role.slug !== 'location-admin') ||
              (role.slug === 'master-admin' && this.$isAllowed(['||', 'master-admin'], true)) ||
              (role.slug === 'site-admin' && this.$isAllowed(['||', 'master-admin', 'site-admin'], true)) ||
              (role.slug === 'location-admin' && this.$isAllowed(['||', 'master-admin', 'site-admin', 'location-admin'], true))
      })
    },
    currentUserLocation () {
      return this.$store.getters['auth/user'].customer.uid
    }
  },
  watch: {
    '$route.path': function () {
      switch (this.$route.name) {
        case 'manageUsersNew':
          this.setAction('Create')
          break
        case 'manageUsersEdit':
          this.setAction('Edit')
          break
        default:
          this.setAction(null)
      }
    }
  },
  methods: {
    formatDate (type) {
      this.user[type] = date.formatDate(new Date(this.user[type]), 'MM/DD/YYYY')
      this.$refs.rBirthDate.hide()
      this.$refs.rStartDate.hide()
    },
    cancelForm () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.user.customer_uid = this.currentUserLocation
    },
    async getUserData (user) {
      this.searchAllUsers = false
      this.$loading(true, `Gathering Data for ${user.label}`)
      try {
        let res = await this.$axios.get(`${this.$consts.GET_USER_DETAILED_URL}/${user.value}`)
        this.user = res.data
        Vue.set(this.user, 'customer_uid', this.user.customer.uid)
        this.user.departments = this.user.departments.map(dep => dep.uid)
        this.user.roles = this.user.roles.map(role => role.uid)

        if (this.user.is_exempt === '1') {
          this.user.is_exempt = true
        } else {
          this.user.is_exempt = false
        }

        this.user.roles.forEach(role => {
          let userRole = this.$store.getters['auth/roleByUid'](role)
          if (userRole && userRole.slug === 'site-admin' && !this.$isAllowed(['||', 'master-admin', 'site-admin'], true)) {
            this.disableForm = true
          }
        })
        this.setAction('Edit')
        this.$loading(false)
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    },
    async addUser () {
      this.$loading(true, `Adding ${this.user.first_name} ${this.user.last_name}`)
      this.user.username = this.user.email
      if (!this.user.customer_uid) {
        this.user.customer_uid = this.$store.getters['auth/user'].customer.uid
      }
      try {
        await this.$store.dispatch('auth/addUser', this.user)
        this.cancelForm()
        this.$loading(false)
        this.$successMsg()
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    },
    async updateUser () {
      this.$loading(true, `Updating ${this.user.first_name} ${this.user.last_name}`)
      if (this.user.password) {
        this.$store.dispatch('auth/updatePassword', { uid: this.user.uid, passwords: { password: this.user.password, password_confirmation: this.user.password_confirmation } })
      }
      if (!this.user.customer_uid) {
        this.user.customer_uid = this.$store.getters['auth/user'].customer.uid
      }
      try {
        await this.$store.dispatch('users/update', this.user)
        this.cancelForm()
        this.$loading(false)
        this.$successMsg()
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    },
    async deleteUser () {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: `Are you sure you want to permantently delete ${this.user.first_name} ${this.user.last_name}?`,
        ok: 'Delete',
        cancel: 'Cancel'
      }).onOk(async () => {
        this.$loading(true, `Deleting ${this.user.first_name} ${this.user.last_name}`)
        try {
          await this.$store.dispatch('users/remove', this.user.uid)
          this.$loading(false)
          this.cancelForm()
          this.$successMsg()
        } catch (e) {
          this.$loading(false)
          this.$failureMsg()
        }
      })
    },
    setAction (which) {
      this.mode = which

      switch (this.mode) {
        case 'Create':
          this.$router.push({ name: 'manageUsersNew' }).catch(err => err)
          break
        case 'Edit':
          if (this.user.uid) {
            this.$router.push({ name: 'manageUsersEdit', params: { uid: this.user.uid } }).catch(err => err)
          } else {
            this.$router.push({ name: 'manageUsers' }).catch(err => err)
          }
          break
        default:
          this.cancelForm()
          this.mode = null
          this.$router.push({ name: 'manageUsers' }).catch(err => err)
      }
    }
  },
  created () {
    this.user.customer_uid = this.currentUserLocation

    switch (this.$route.name) {
      case 'manageUsersNew':
        this.setAction('Create')
        break
      default:
        this.setAction(null)
        this.cancelForm()
        this.mode = null
    }
  }
}
</script>
